import moment from "moment";
// #todo: (COUNT(DISTINCT ?resolveResultsRelated) AS ?numberOfRelated) -> macht erhebliche Probleme, inffull fehler
const industriesToFilter = (industries) => {    
    let work = '';
    if(industries && industries.length > 0 && industries[0].name !== 'Alle') {
      work = 'FILTER (';      
      industries.map((item, index) => {        
        if(index > 0) { work += '|| '}
        work += `?labelGroup = "${item.name}"`;
      });
      work += ')';
    }
    else { }
    return work;
  }

  export function filterVerfahrensStufe(verfahrensStufe) {
    let filter = '';
    switch(verfahrensStufe)
    {
      case 'Neu':
        filter = 'FILTER (?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_7_1_Z1> ';
        filter += '|| ?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_1_Z2> ';
        filter += '|| ?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_1_Z3> ';
        filter += '|| ?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_2_Z3> ';
        filter += '|| ?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_1_Z5>)';
        return filter;
  
      case 'Vorinfo':
        filter = 'FILTER (?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_1_Z1>)';
        return filter;
  
      case 'Zuschlag':
        filter = 'FILTER (?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_7_2_Z1> ';
        filter += '|| ?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_2_Z1> ';
        filter += '|| ?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_2_Z2> ';
        filter += '|| ?type = <https://deepick.eu/schema/at-kdvo-raw/v1/type#KD_8_1_Z4>)';
        return filter;
      default:
        return '';
    }
  }

export const getQuery = (props) => {
  // fix me "invalid date"
  // console.log('props.filter.kdqLastmodTo', props.filter.kdqLastmodTo);
  // console.log('props.filter.kdqLastmodTo', (props.filter.kdqLastmodTo));

    let filterFrom = '';
    if(props.filter.downloadFrom && moment(props.filter.downloadFrom).isValid()) {            
        filterFrom = 'FILTER (?downloaded >= "' + new Date(props.filter.downloadFrom).toISOString() + '"^^xsd:dateTime)';      
    }
    
    let filterTo = '';
    if(props.filter.downloadTo && moment(props.filter.downloadTo).isValid()) {
      var nextDay = new Date();
        nextDay.setTime(props.filter.downloadTo.getTime() +  (1 * 24 * 60 * 60 * 1000));
        filterTo = 'FILTER (?downloaded <= "' + nextDay.toISOString() + '"^^xsd:dateTime)';
    }
    

    let filterLastmodFrom = '';
    if(props.filter.kdqLastmodFrom && props.filter.kdqLastmodFrom !== -3600000 && moment(props.filter.kdqLastmodFrom).isValid()) {      
      filterLastmodFrom = 'FILTER (?kdqLastmod >= "' + new Date(props.filter.kdqLastmodFrom).toISOString() + '"^^xsd:dateTime)';      
    }
    
    let filterLastmodTo = '';
    if(props.filter.kdqLastmodTo && props.filter.kdqLastmodTo !== -3600000 && moment(props.filter.kdqLastmodTo).isValid()) {
      var nextDay = new Date();
        nextDay.setTime(props.filter.kdqLastmodTo.getTime() +  (1 * 24 * 60 * 60 * 1000));
        filterLastmodTo = 'FILTER (?kdqLastmod <= "' + nextDay.toISOString() + '"^^xsd:dateTime)';
    }
  
    let filterCPV = '';
    if(props.filter.cpv && props.filter.cpv !== '') {
      // filterCPV = `FILTER regex(?cpvMain, "${props.filter.cpv}", "i")`;
      filterCPV = `FILTER (?cpvMain = "${props.filter.cpv}")`
    }

    let filterContractor = props.filter.contractor ? `FILTER (?contractor = <${props.filter.contractor}>)` : '';
    let filterContractorName = props.filter.contractorName ? `FILTER (regex(str(?contractorName), "${props.filter.contractorName}" ) || regex(str(?contractorPublicationName), "${props.filter.contractorName}" ))` : '';

    let filterContractingBodies = '';
    if(props.filter.contractingBodies) {
      filterContractingBodies += `FILTER (`;
      for (var i = 0; i < props.filter.contractingBodies.length; i++) {            
        filterContractingBodies += `?contractingBody = <${props.filter.contractingBodies[i].toLowerCase()}> ${(props.filter.contractingBodies.length > 1 && i < props.filter.contractingBodies.length - 1) ? ' || ' : ''}`;
      }
      filterContractingBodies += `)`;
    }

    let filterContractingBody = ''
    let filterContractingBodyName = props.filter.contractingBodyName ? `FILTER (regex(str(?contractingBodyName), "${props.filter.contractingBodyName}" ) || regex(str(?contractingBodyNamePublication), "${props.filter.contractingBodyName}" ))` : '';

    let filterIndustry = props.filter.industries ? industriesToFilter(props.filter.industries) : '';
    let filterIndustryLabel = (props.filter.industryLabel && props.filter.industryLabel !== '-') ? `FILTER (?labelName="${props.filter.industryLabel}")` : '';
    let filterProcessStage = (props.filter.verfahrensStufe && props.filter.verfahrensStufe !== '-') ? filterVerfahrensStufe(props.filter.verfahrensStufe) : '';
    
    let filterTitle = props.filter.title ? `FILTER (CONTAINS(LCASE(?title),"${props.filter.title.toLowerCase()}"))` : '';  

    let filterText = props.filter.text ? `FILTER (regex(str(?shortDescription), "${props.filter.text}" ) || regex(str(?title), "${props.filter.text}" ))` : '';

    let optionalNumberOfFiles1 = '';
    let optionalNumberOfFiles2 = '';
    
    if(props.columns.some( col => col['colId'] === 'numberOfFiles' ))  {
      
        optionalNumberOfFiles1 = '(COUNT(DISTINCT ?file) AS ?numberOfFiles) ';
        optionalNumberOfFiles2 = `OPTIONAL {
            ?publication at-kdvo-raw:urlDocument ?urlDocument.
            ?downloadId kg:interceptedUrl ?urlDocument.
            ?file kg:downloadId ?downloadId.
          }`;
    }

    let filterOrganisation = '';
    if (props.filter?.orgId && props.filter.orgId !== '' && !props.filter?.isAdditionalContact && !props.filter?.isMentionedInTextDocument) {
      filterOrganisation = `FILTER (?contractor = <${props.filter?.orgId}> || ?contractingBody = <${props.filter?.orgId}>)`;
    }

    let filterIsAdditionalContact = '';
    if(props.filter?.isAdditionalContact) {
      filterIsAdditionalContact = `FILTER (?additionalContact = <${props.filter?.orgId}>)`;
    }

    let filterMentionedInTextDocument = '';
    if(props.filter?.mentionedOrgId) {
      filterMentionedInTextDocument = `
        ?textDocument kg:mentionsOrganization <${props.filter?.mentionedOrgId}>.
        ?downloadedFile kg:textDocument ?textDocument.
        ?downloadedFile kg:downloadId ?download.
        ?download kg:interceptedUrl ?interceptedUrl.
        ?publication at-kdvo-raw:urlDocument ?interceptedUrl.
        FILTER (?contractingBody != <${props.filter?.mentionedOrgId}>)
      `;
    }

    let selectContractingBody = `
      ${filterOrganisation || props.filter.contractingBody || filterContractingBodies || filterMentionedInTextDocument || filterContractingBodyName ? '' : 'OPTIONAL {'}        
        ?resolveResult kg:contractingBody ?contractingBody.
        ?contractingBody core:name ?contractingBodyName.
      ${filterOrganisation || props.filter.contractingBody || filterContractingBodies || filterMentionedInTextDocument || filterContractingBodyName ? '' : '}'}
    `;
    let bindContractingBody = (props.filter.contractingBody) ? `BIND(<${props.filter.contractingBody}> AS ?contractingBody)` : ''
    
    let selectContractor = `
      ${filterOrganisation || props.filter.contractor ? '' : 'OPTIONAL {'}        
        ?resolveResult kg:contractor ?contractor.
        ?contractor core:name ?contractorName.
      ${filterOrganisation || props.filter.contractor ? '' : '}'}
    `;
    let bindContractor = (props.filter.contractor) ? `BIND(<${props.filter.contractor}> AS ?contractor)` : ''
        
    let queryString = `
    PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>
    PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
    PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
    PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

    SELECT 
        ?publication
        ?cpvMain
        ?labelGroup 
        ?labelName
        ?processStage 
        ?contractingBody 
        ?contractingBodyName 
        ?contractingBodyNamePublication
        ?title (COUNT(DISTINCT ?contractor) AS ?numberOfContractor) 
        (COUNT(DISTINCT ?contractingBody) AS ?numberOfContractingBody) 
        ${optionalNumberOfFiles1}
        ?contractor 
        ?contractorName 
        ?contractorPublicationName 
        ?downloaded
        ?kdqLastmod
        ?shortDescription
        ?totalValue
        
        (COUNT(DISTINCT ?resolveResultsRelated) AS ?numberOfRelated)
WHERE
        {
          ?publication rdf:type ?type.
          ?publication at-kdvo-raw:downloaded ?downloaded.
          ?publication at-kdvo-raw:kdqLastmod ?kdqLastmod.
          ?publication at-kdvo-raw:processStage ?processStage.
          ?publication at-kdvo-raw:title ?title.
          ?publication at-kdvo-raw:contractingBody ?contractingBodyPublication.
          ?contractingBodyPublication at-kdvo-raw:officialName ?contractingBodyNamePublication.
          ?resolveResult kg:publication ?publication.

          OPTIONAL {
            ?publication at-kdvo-raw:shortDescription ?shortDescription.        
          }
                    
          OPTIONAL {
            ?publication at-kdvo-raw:totalValue ?totalValue.
          }
      
          ${bindContractingBody}
          ${selectContractingBody}          

          ${bindContractor}
          ${selectContractor}          
  
          OPTIONAL{
            ?publication at-kdvo-raw:contractor ?contractorPublication.
            ?contractorPublication at-kdvo-raw:officialName ?contractorPublicationName.
          }

          OPTIONAL{
            ?publication at-kdvo-raw:cpvMain ?cpvMain.
          }

          OPTIONAL{
            ?resolveResult kg:label ?label.
            ?label core:name ?labelName.
            ?label core:group ?labelGroup.
          }

          OPTIONAL {
            ?resolveResult kg:additionalContact ?additionalContact.
          }

          OPTIONAL {
            ?resolveResult kg:tenderingProcedure ?tenderingProcedure.
            ?resolveResultsRelated kg:tenderingProcedure ?tenderingProcedure.
          }

          ${filterMentionedInTextDocument}

        ${optionalNumberOfFiles2}
        ${filterOrganisation}
        ${filterFrom}
        ${filterTo}
        ${filterLastmodFrom}
        ${filterLastmodTo}
        ${filterContractor}
        ${filterContractorName}
        ${filterContractingBody}
        ${filterContractingBodies}
        ${filterContractingBodyName}
        ${filterIsAdditionalContact}        
        ${filterIndustry}
        ${filterIndustryLabel}
        ${filterProcessStage}
        ${filterText}
        ${filterTitle}
        ${filterCPV}
      }
    GROUP BY ?publication
    ORDER BY DESC(?downloaded)
    LIMIT ${props.records}
    `
    // if(props.filter?.isMentionedInTextDocument ) {
    //  console.log('queryString', queryString);
    // }    
    
    return queryString
  }