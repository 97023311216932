export const version = '2.30';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isAuthenticated: false,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: 'EUR',
  isNavbarVerticalCollapsed: false,
  defaultIndustry: 'tech',
  navbarStyle: 'transparent',
  originalUrl: null
};

export const tableOverlays = {
  overlayNoRowsTemplate: '<span style="padding: 10px; border: 0.5px solid #444; background: lightgoldenrodyellow">Nichts gefunden</span>',
  overlayLoadingTemplate: '<span style="padding: 10px; border: 0.5px solid #444;">Lädt ...</span>',
  errorMessage: 'Beim Laden ist ein Fehler aufgetreten. Vielleicht sind Sie nicht mehr angemeldet?'
}

export const availableIndustries = [
  {name: 'Allgemein', icon: 'hard-hat', color: '#61a0a8'},
  {name: 'Bau', icon: 'hard-hat', color: '#5b6069'},
  {name: 'Beratung', icon: 'user-tie', color: '#2c7be5'},
  {name: 'FM', icon: 'hard-hat', color: '#5b6069'},    
  {name: 'Tech', icon: 'microchip', color: '#326bc7'}    
];

export const autoCompleteDefaultItems = [
  {
    url: `/bieter/36ab18e3-5094-4ad0-8ff9-ccd7c51812d2`,
    breadCrumbTexts: ['Bieter ', 'Microsoft Österreich GmbH'],
    category: 'recentlyBrowsedItem'
  },
  {
    url: `/auftraggeber/ba8a21df-44fb-4940-bb9d-ffc43ff84904`,
    breadCrumbTexts: ['AG ', ' Stadt Wien'],
    category: 'recentlyBrowsedItem'
  }
];

export const activityTypes = [
  {
      text: "Energie",
      value: "_ENERGY"
  },
  {
      text: "Verkehr",
      value: "_TRAFFIC"
  },
  {
      text: "Verteidigung",
      value: "DEFENCE"
  },
  {
      text: "Wirtschaft und Finanzen",
      value: "ECONOMIC_AND_FINANCIAL_AFFAIRS"
  },
  {
      text: "Bildung und Forschung",
      value: "EDUCATION"
  },
  {
      text: "Umwelt und Landwirtschaft",
      value: "ENVIRONMENT"
  },
  {
      text: "Allgemein",
      value: "GENERAL_PUBLIC_SERVICES"
  },
  {
      text: "Gesundheit",
      value: "HEALTH"
  },
  {
      text: "Immobilien und Infrastruktur",
      value: "HOUSING_AND_COMMUNITY_AMENITIES"
  },
  {
      text: "Ordnung und Sicherheit",
      value: "PUBLIC_ORDER_AND_SAFETY"
  },
  {
      text: "Erholung, Kultur und Religion",
      value: "RECREATION_CULTURE_AND_RELIGION"
  },
  {
      text: "Sozialer Schutz",
      value: "SOCIAL_PROTECTION"
  }
];

export const getActivityTypeText = (value) => {
  const activityType = activityTypes.find((type) => type.value === value);
  return activityType ? activityType.text : null;
}

export default { activityTypes, getActivityTypeText, version, navbarBreakPoint, topNavbarBreakpoint, settings, availableIndustries, autoCompleteDefaultItems };
