import { version } from './config';

export const homeRoutes = {
  name: 'Dashboard',
  to: '/',
  exact: true,
  icon: 'chart-pie'
};

export const editRoutes = {
  name: 'Redaktion',
  to: '/curator',
  icon: 'edit',
  children: [
    { to: '/curator/documents_kd', name: 'Veröffentlichungen' },
    { to: '/curator/downloads', name: 'Downloads' },
    { to: '/curator/keyfigures', name: 'Kennzahlen' },
    { to: '/curator/missingdomains', name: 'Fehlende Domänen' }
  ]
};

export const trendRoutes = {
  name: 'Trends',
  to: '/trends',
  icon: ['fab', 'hotjar'],
  children: [
    { to: '/branche/marketleaderfilter', name: 'Marktführer' },
    { to: '/branche/newcustomers', name: 'Neue Kunden' },
    { to: '/branche/newcontractors', name: 'Neue Lieferanten' },
    { to: '/branche/newcontractorsmarket', name: 'Neu im Markt' },
    { to: '/trends/searchtermhistory', name: 'Begriffe' },
    // { to: '/trends/schiefermarketreport', name: 'Schiefer Report' },
    { to: '/trends/techquarterly', name: 'Tech Quarterly' },
    { to: '/trends/event-network', name: 'e-Gov 22' },
    { to: '/trends/event-network-egov23', name: 'e-Gov 23' },
    { to: '/trends/generaloverview', name: 'Überblick' },
    // { to: '/trends/event-network-lsz23', name: 'LSZ 23' }
  ]
};
export const widgetsRoutes = {
  name: 'Auftraggeber',
  to: '/auftraggeber',
  exact: true,
  icon: 'city',
  children: [
    { to: '/branche/newcontractors', name: 'Neue Lieferanten' }
  ]
};

export const chatRoutes = {
  name: 'Veröffentlichungen',
  to: '/veroeffentlichungen',
  exact: true,
  icon: 'newspaper'
};

export const searchDocumentsRoutes = {
  name: 'Unterlagen',
  to: '/search-documents',
  exact: true,
  icon: 'file-alt'
};

export const contractorRoutes = {
  name: 'Lieferanten',
  exact: true,
  icon: 'euro-sign',
  children: [
    // {
    // to: '/branche/dominationmatrix',
    // name: 'Dominanz Matrix'
    // },
    { to: '/org/search', name: 'Suchen' },
    { to: '/branche/dominance', name: 'Dominanz'},
    { to: '/branche/dominancetimelapse', name: 'Dominanz Zeitraffer'},
    { to: '/branche/dominance2', name: 'Zuschlagsverteilung'},
    { to: '/org/marketnetworkfilter', name: 'Netzwerk'},
    { to: '/branche/top100contractors', name: 'Top 100' },
    { to: '/branche/newcustomers', name: 'Neue Kunden' }
    // { to: '/branche/contractorsbyindustry', name: 'Zuletzt' },
    // { to: '/branche/consulting/timeline', name: 'Timeline' },
    // { to: '/branche/consulting/leistungenskills', name: 'Leistungen & Skills' }
  ]
};

export const InsolvenzenRoutes = {
  name: 'Insolvenzen',
  to: '/insolvenzen',
  icon: 'skull-crossbones'
};

export const brancheVergabeRoutes = {
  name: 'Vergabe',
  to: '/plugins',
  icon: 'balance-scale',
  children: [
    { to: '/branche/vergabe/vergabeplattformen', name: 'Vergabeplattformen' },
    { to: '/branche/vergabe/vergabekontrollverfahren', name: 'Vergabekontrollverfahren' },
    { to: '/branche/newcontactofs', name: 'Neue Vertretungen' },
    { to: '/branche/top100contactofs', name: 'Top Vertretungen' },
    { to: '/veroeffentlichungen/cpvbrowser', name: 'CPV Browser' }
  ]
};

export const documentationRoutes = {
  name: 'Dokumentation',
  to: '/documentation',
  exact: true,
  icon: 'book',
  children: [
    { to: '/documentation/general', name: 'Allgemein' },
    { to: '/documentation/sources', name: 'Quellen' },
    { to: '/documentation/cpv', name: 'CPV Zuordnungen' }
  ]
};

export const changelogRoutes = {
  name: 'ChangeLog',
  to: '/changelog',
  exact: true,
  icon: 'code-branch',
  badge: {
    text: `v${version}`,
    color: 'soft-primary'
  }
};

export default [
  homeRoutes,
  trendRoutes,
  chatRoutes,
  searchDocumentsRoutes,
  widgetsRoutes,
  contractorRoutes,
  // InsolvenzenRoutes,
  brancheVergabeRoutes,
  // editRoutes,  
  documentationRoutes,
  changelogRoutes
];
