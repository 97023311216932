import React, { useEffect, Fragment, useContext, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import AppContext from './context/Context';
import * as sparqlQueries from './sparqlQueries.js';
import SparqlHook from './SparqlHook.js';

const App = () => {
  let appInsights = null;
  const { isAuthenticated, setIsAuthenticated, currentUser, setCurrentUser, setCurrentUserPic, organizationList, setOrganizationList, setOrganizationLookupList, originalUrl, setOriginalUrl } = useContext(AppContext);
  const [ isValidated, setIsValidated ] = useState(false);
  const { runQuery } = SparqlHook();  
  
  // if (process.env.NODE_ENV === 'production') clarity.init('m4sj36j33n');

  if(!originalUrl) {    
    // console.log('window.location.href', window.location.href);    
    setOriginalUrl(window.location.href);
  }
  const checkLogin = async () => {    
    let whoAmI = await sparqlQueries.whoAmI();
    let checkedUser = await sparqlQueries.resolveUser(whoAmI);
    if(checkedUser) {
      setIsAuthenticated(true);
      if(checkedUser[0].name !== currentUser.name || checkedUser[0].user !== currentUser.user || checkedUser[0].roles !== currentUser.roles || checkedUser[0].organization !== currentUser.organization || checkedUser[0].email !== currentUser.email || checkedUser[0].profile_picture !== currentUser.profile_picture || checkedUser[0].defaultIndustry !== currentUser.defaultIndustry) {
        setCurrentUserPic(checkedUser[0].profile_picture);
        setCurrentUser(checkedUser[0]);
      }
      if(!organizationList || organizationList.length === 0) {
        let contractorList = [];
        let queryString = `
        PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
        PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
        PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
        PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>

        SELECT DISTINCT (?org AS ?id) ("contractor" AS ?category) ?name (COUNT(?publication) AS ?count) (MAX(?kdqLastmod) AS ?last) (MIN(?kdqLastmod) AS ?first) ?mainType
        WHERE {
          ?publication at-kdvo-raw:title ?title;
            at-kdvo-raw:downloaded ?downloaded;
            at-kdvo-raw:kdqLastmod ?kdqLastmod;
            rdf:type ?type.

          ?resolveResult kg:publication ?publication.
          ?resolveResult kg:contractor ?org.

          ?org core:name ?name.
          ?org core:mainType ?mainType.

        }
        GROUP BY ?org
        ORDER BY ?name
        `
        runQuery({ query: queryString})
        .then(result => {
          contractorList = result;
          queryString = `
          PREFIX kg: <https://deepick.eu/schema/kg/v1/pred#>
          PREFIX at-kdvo-raw: <https://deepick.eu/schema/at-kdvo-raw/v1/pred#>
          PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
          PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
          PREFIX core: <https://deepick.eu/schema/sql-import/v1/pred#>

          SELECT DISTINCT (?org AS ?id) ("contractingBody" AS ?category) ?name (COUNT(?publication) AS ?count) (MAX(?kdqLastmod) AS ?last) (MIN(?kdqLastmod) AS ?first) ?mainType
          WHERE {
            ?publication at-kdvo-raw:title ?title;
              at-kdvo-raw:downloaded ?downloaded;
              at-kdvo-raw:kdqLastmod ?kdqLastmod;
              rdf:type ?type.

            ?resolveResult kg:publication ?publication.
            ?resolveResult kg:contractingBody ?org.

            ?org core:name ?name.
            ?org core:mainType ?mainType.
          }

          GROUP BY ?org
          ORDER BY ?name
          `
          runQuery({ query: queryString})
          .then(result => {
            // #todo kommt hier dreimal her bekommt organizationList gesetzt ist
            // console.log('organizationList', organizationList);
            let fullList = contractorList.concat(result);            
            setOrganizationList(fullList);            
            fullList = fullList.map(x => ({'id': x.id, 'name': x.name, 'mainType': x.mainType}))            
            const ids = fullList.map(o => o.id);
            setOrganizationLookupList(fullList.filter(({id}, index) => !ids.includes(id, index + 1)));
          });
        });

      }
      setIsValidated(true);
    }
    else
    {
      // console.log(new Date(), 'No valid redenticals');
      setIsAuthenticated(false);
      setCurrentUser({});
      setCurrentUserPic('');
      setIsValidated(true);
    }
  }

  useEffect( () => {
    checkLogin();
  },[isValidated, isAuthenticated]);

  if(isValidated) {
    return(
      
        <Router>
          
            <Layout />
          
        </Router>
      
    )
  }
  else {
    return(
      <Fragment></Fragment>
    )
  }
}

export default App;